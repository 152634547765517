<script>
    export let place = {};
    import { createEventDispatcher } from 'svelte';
    const dispatch = createEventDispatcher();
    // export let bgClass = '';
    // export let bgImage = '';
    export let index;
    export let lang = '';
    // export let name;
    let currBgImage = '';
    // export let isActive = false;
    function toggleActive() {
        if (!place.image) return;
        place.isActive = !place.isActive;
        dispatch('state_changed', {
            name: place.name,
            type: 'places',
            isActive: place.isActive,
            index,
        });
    }

    $: place.isActive && place.image
        ? (currBgImage = `/img/boxes/${lang}/${place.image}_hover.png`)
        : (currBgImage = `/img/boxes/${lang}/${place.image}.png`);
</script>

{#if currBgImage && place.image}
    <div
        class={place.class}
        style="background-image:url({currBgImage})"
        on:click={toggleActive}
    />
{/if}
