<script>
    import Fullscreen from 'svelte-fullscreen';
    import { getGameData } from './licenseHandler';
    import Place from './Place.svelte';
    import Protection from './Protection.svelte';
    import Popup from './Popup.svelte';
    import Result from './Result.svelte';
    import { renderData } from './renderDataStore.js';
    import { protectionsInit } from './renderDataStore.js';
    import { preloadImages } from './renderDataStore.js';
    import { messages } from './gameData.js';
    const game = checkGame();
    const mode = game === 'tutorial' ? 'tutorial' : 'game';
    const places = $renderData[mode];
    const protections = $protectionsInit;
    let isFullScreen = false;
    let isPlaceSelected = false;
    let lang = '';
    let isResultVisible = false;
    let validity = game === 'tutorial' ? 'valid' : 'waiting';
    let testImg = '';
    function checkGame() {
        let connectionData = new URL(window.location);
        return connectionData.searchParams.get('mode');
    }

    let validityCheckInterval;
    const credentials = getGameCredentials(game);
    initialCheckValidity();
    repeatCheckValidity();

    async function initialCheckValidity() {
        if (!credentials || mode === 'tutorial') return;
        const validCheck = await getGameData(game, credentials);
        console.log(validCheck);
        validity = validCheck;
    }

    function repeatCheckValidity() {
        if (!credentials || mode === 'tutorial') return;
        validityCheckInterval = setInterval(async () => {
            const validCheck = await getGameData(game, credentials);
            validity = validCheck;
        }, 10000);
    }
    function getGameCredentials(game) {
        if (mode === 'tutorial') return;
        const cred = JSON.parse(localStorage.getItem(game));
        if (!cred) {
            validity = 'missingcredentials';
        }
        console.log(cred);
        return cred;
    }

    async function changeState(event) {
        console.log(event.detail);
        const target = event.detail.type;

        if (!event.detail.isActive && target === 'places') {
            isPlaceSelected = false;
            return;
        }

        if (!event.detail.isActive) {
            return;
        }
        if (target === 'places') {
            for (let i = 0; i < places.length; i++) {
                places[i].isActive = false;
            }
            isPlaceSelected = event.detail.name;
            places[event.detail.index].isActive = true;
        } else {
            protections[event.detail.index].isActive = true;
        }
    }

    function displayResults() {
        if (!isPlaceSelected) return;
        isResultVisible = true;
    }
    function closeResults() {
        clearState();
        isResultVisible = false;
    }

    function clearState() {
        for (let i = 0; i < places.length; i++) {
            places[i].isActive = false;
        }
        for (let i = 0; i < protections.length; i++) {
            protections[i].isActive = false;
        }
        isPlaceSelected = false;
    }
    function goToTrainerPanel() {
        clearInterval(validityCheckInterval);
        window.location.assign('/trener');
    }

    function gatherImagesToPreload(language) {
        const arr = [];
        places.forEach((el) => {
            if (el.image) {
                arr.push(`/img/boxes/${language}/${el.image}.png`);
                arr.push(`/img/boxes/${language}/${el.image}_hover.png`);
                console.log(el);
            }
        });
        protections.forEach((el) => {
            if (el.image) {
                arr.push(`/img/boxes/${language}/${el.image}.png`);
                arr.push(`/img/boxes/${language}/${el.image}_hover.png`);
            }
        });
        for (let dice = 1; dice < 7; dice++) {
            arr.push(`/img/dice/dice${dice}_hover.png`);
            arr.push(`/img/dice/dice${dice}.png`);
        }
        preloadImages.forEach((el) => {
            arr.push(el.replace('$$$language$$$', language));
        });

        return arr;
    }
    // preloadAll();
    async function preloadAll(language) {
        const beforeLoop = Date.now();
        const arr = gatherImagesToPreload(language);

        const promises = arr.map(async (el) => {
            const readyEl = await preload(el);
            return readyEl;
        });

        await Promise.all(promises);

        const afterLoop = Date.now();
        console.log(
            'preloading is done in',
            afterLoop - beforeLoop,
            'miliseconds'
        );
    }

    function preload(src) {
        return new Promise(function (resolve) {
            let img = new Image();
            img.src = src;
            img.onload = resolve;
            // img.onload = () => {
            //     img.decode().then(() => resolve(img));
            // };
        });
    }
    function beforeUnload(e) {
        e.preventDefault();
        return (e.returnValue = '');
    }
    $: if (lang === 'pl' || lang === 'en') preloadAll(lang);
</script>

<svelte:head>
    <html lang={lang === 'eng' ? 'en' : lang} />
</svelte:head>

<svelte:window on:beforeunload|preventDefault={beforeUnload} />
<Fullscreen let:onRequest let:onExit>
    <div class={isFullScreen ? 'main' : 'language'}>
        <div class={isFullScreen ? 'hidden' : 'language-buttons'}>
            <button
                class={isFullScreen ? 'hidden' : 'language-buttons_pl'}
                class:hidden={isFullScreen}
                on:click={() => {
                    isFullScreen = true;
                    lang = 'pl';
                    onRequest();
                }}
            />
            <button
                class={isFullScreen ? 'hidden' : 'language-buttons_en'}
                on:click={() => {
                    isFullScreen = true;
                    lang = 'en';
                    onRequest();
                }}
            />
        </div>
        {#if isFullScreen}
            {#if validity === 'waiting'}
                <p>waiting...</p>
            {:else if validity === 'valid'}
                <div class="main-places">
                    {#each places as place, i}
                        <Place
                            {lang}
                            {place}
                            on:state_changed={changeState}
                            index={i}
                        />
                    {/each}
                </div>
                <div class="main-protection">
                    <div class="main-protection_divider1" />
                    {#each protections as protection, i}
                        <Protection
                            {lang}
                            {protection}
                            on:state_changed={changeState}
                            index={i}
                        />
                    {/each}
                    <div class="main-protection_divider2" />
                </div>
                <div
                    class={isPlaceSelected
                        ? 'main-confirmation--active'
                        : 'main-confirmation--dead'}
                    on:click={displayResults}
                >
                    <div class="main-confirmation_OK" />
                </div>

                {#if isResultVisible}
                    <Result
                        {lang}
                        {mode}
                        place={isPlaceSelected}
                        {protections}
                        on:close_results={closeResults}
                    />
                {/if}
            {:else if validity === 'wrongcode' || validity === 'wronggame' || validity === 'expired' || validity === 'missingcredentials'}
                <Popup
                    data={messages[lang][validity]}
                    on:popupConfirm={goToTrainerPanel}
                />
            {:else}
                <p>something else</p>
            {/if}
        {/if}
    </div>
</Fullscreen>
