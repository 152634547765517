<script>
    export let mode = '';
    export let place = '';
    export let lang = '';
    export let protections = '';
    import Karma from './Karma.svelte';
    import Dice from './Dice.svelte';
    import { data } from './gameData';
    let currentData = data[lang][mode][place];
    const isProtected = checkProtection(currentData, protections);
    export let diceResult = 0;

    function checkProtection(placedata, arr_protections) {
        console.log(placedata);
        for (let el of arr_protections) {
            console.log('in loop', el.name, placedata.protection);
            if (el.name.toLowerCase() === placedata.protection && el.isActive) {
                return true;
            }
        }
        return false;
    }
</script>

{#if currentData.effect === 'display' || (currentData.effect === 'display/toss' && isProtected)}
    <Karma {currentData} {isProtected} {lang} on:close_results />
{:else if !diceResult}
    <Dice bind:diceResult {lang} />
{:else}
    <Karma {currentData} {isProtected} {diceResult} {lang} on:close_results />
{/if}
