export const data = {
    pl: {
        tutorial: {
            Vipers: {
                name: 'Gniazdo żmij',
                effect: 'display',
                protection: 'careful',
                protectedKarma: 1,
                badKarma1: 6,
                badKarma2: 'nie dotyczy',
                badKarma3: 'nie dotyczy',
                badKarma4: 'nie dotyczy',
                badKarma5: 'nie dotyczy',
                badKarma6: 'nie dotyczy',
                worseKarma1: 'nie dotyczy',
                worseKarma2: 'nie dotyczy',
                worseKarma3: 'nie dotyczy',
                worseKarma4: 'nie dotyczy',
                worseKarma5: 'nie dotyczy',
                worseKarma6: 'nie dotyczy',
                protectedDescription:
                    'Ostrożnie przemieszczasz się przez zarośla. Słyszysz ciche szelesty w pobliskich krzakach, jednak wszystko się uspokaja kiedy powoli oddalasz się w swoją stronę.',
                badDescription1:
                    'Mijasz pobliskie zarośla, podśpiewując sobie dla dodania animuszu. Wtem dosięga Cię mściwy syk! Nim zdążyłeś uskoczyć, żmija rzuciła się na Twoje łydki i dotkliwie Cię pokąsała.',
                badDescription2: 'nie dotyczy',
                badDescription3: 'nie dotyczy',
                badDescription4: 'nie dotyczy',
                badDescription5: 'nie dotyczy',
                badDescription6: 'nie dotyczy',
                worseDescription1: 'nie dotyczy',
                worseDescription2: 'nie dotyczy',
                worseDescription3: 'nie dotyczy',
                worseDescription4: 'nie dotyczy',
                worseDescription5: 'nie dotyczy',
                worseDescription6: 'nie dotyczy',
            },
            Shrubbery: {
                name: 'Kolczaste krzaki',
                effect: 'display/toss',
                protection: 'none',
                protectedKarma: 1,
                badKarma1: 1,
                badKarma2: 1,
                badKarma3: 1,
                badKarma4: 1,
                badKarma5: 1,
                badKarma6: 1,
                worseKarma1: 'nie dotyczy',
                worseKarma2: 'nie dotyczy',
                worseKarma3: 'nie dotyczy',
                worseKarma4: 'nie dotyczy',
                worseKarma5: 'nie dotyczy',
                worseKarma6: 'nie dotyczy',
                protectedDescription:
                    'Przy drodze rośnie wiele kolczastych roślin, o które łatwo się pokaleczyć. Udało Ci się jednak uniknąć kłopotów.',
                badDescription1:
                    'Przy drodze rośnie wiele kolczastych roślin, o które łatwo się pokaleczyć. Udało Ci się jednak uniknąć kłopotów.',
                badDescription2:
                    'Przy drodze rośnie wiele kolczastych roślin, o które łatwo się pokaleczyć. Udało Ci się jednak uniknąć kłopotów.',
                badDescription3:
                    'Przy drodze rośnie wiele kolczastych roślin, o które łatwo się pokaleczyć. Udało Ci się jednak uniknąć kłopotów.',
                badDescription4:
                    'Przy drodze rośnie wiele kolczastych roślin, o które łatwo się pokaleczyć. Udało Ci się jednak uniknąć kłopotów.',
                badDescription5:
                    'Przy drodze rośnie wiele kolczastych roślin, o które łatwo się pokaleczyć. Udało Ci się jednak uniknąć kłopotów.',
                badDescription6:
                    'Przy drodze rośnie wiele kolczastych roślin, o które łatwo się pokaleczyć. Udało Ci się jednak uniknąć kłopotów.',
                worseDescription1: 'nie dotyczy',
                worseDescription2: 'nie dotyczy',
                worseDescription3: 'nie dotyczy',
                worseDescription4: 'nie dotyczy',
                worseDescription5: 'nie dotyczy',
                worseDescription6: 'nie dotyczy',
            },
        },
        game: {
            Village: {
                name: 'Bagienna wioska',
                effect: 'display',
                protection: 'none',
                protectedKarma: 2,
                badKarma1: 2,
                badKarma2: 'nie dotyczy',
                badKarma3: 'nie dotyczy',
                badKarma4: 'nie dotyczy',
                badKarma5: 'nie dotyczy',
                badKarma6: 'nie dotyczy',
                worseKarma1: 'nie dotyczy',
                worseKarma2: 'nie dotyczy',
                worseKarma3: 'nie dotyczy',
                worseKarma4: 'nie dotyczy',
                worseKarma5: 'nie dotyczy',
                worseKarma6: 'nie dotyczy',
                protectedDescription:
                    'Mijasz wioskę, zamieszkałą przez dziwne istoty. Mają zwyczaj obrzucać obcych śmierdzącym błotem. Wiedzą co robią, bo naprawdę ciężko zmyć z siebie ten odór.',
                badDescription1:
                    'Mijasz wioskę, zamieszkałą przez dziwne istoty. Mają zwyczaj obrzucać obcych śmierdzącym błotem. Wiedzą co robią, bo naprawdę ciężko zmyć z siebie ten odór.',
                badDescription2: 'nie dotyczy',
                badDescription3: 'nie dotyczy',
                badDescription4: 'nie dotyczy',
                badDescription5: 'nie dotyczy',
                badDescription6: 'nie dotyczy',
                worseDescription1: 'nie dotyczy',
                worseDescription2: 'nie dotyczy',
                worseDescription3: 'nie dotyczy',
                worseDescription4: 'nie dotyczy',
                worseDescription5: 'nie dotyczy',
                worseDescription6: 'nie dotyczy',
            },
            Swamps: {
                name: 'Bagna',
                effect: 'display',
                protection: 'breathing-spell',
                protectedKarma: 1,
                badKarma1: 5,
                badKarma2: 'nie dotyczy',
                badKarma3: 'nie dotyczy',
                badKarma4: 'nie dotyczy',
                badKarma5: 'nie dotyczy',
                badKarma6: 'nie dotyczy',
                worseKarma1: 'nie dotyczy',
                worseKarma2: 'nie dotyczy',
                worseKarma3: 'nie dotyczy',
                worseKarma4: 'nie dotyczy',
                worseKarma5: 'nie dotyczy',
                worseKarma6: 'nie dotyczy',
                protectedDescription:
                    'Wszedłeś na bagna. Przeprawa choć uciążliwa, jest w miarę spokojna - tylko powietrze jakieś takie ciężkie, że można by topór powiesić.',
                badDescription1:
                    'Wszedłeś na bagna. Przeprawa choć uciążliwa, jest w miarę spokojna - tylko te gryzące opary drażnią Twoje gardło. Niby nic się nie dzieje, ale zacząłeś dziwnie pokasływać.',
                badDescription2: 'nie dotyczy',
                badDescription3: 'nie dotyczy',
                badDescription4: 'nie dotyczy',
                badDescription5: 'nie dotyczy',
                badDescription6: 'nie dotyczy',
                worseDescription1: 'nie dotyczy',
                worseDescription2: 'nie dotyczy',
                worseDescription3: 'nie dotyczy',
                worseDescription4: 'nie dotyczy',
                worseDescription5: 'nie dotyczy',
                worseDescription6: 'nie dotyczy',
            },
            Mountains: {
                name: 'Góry szaleństwa',
                effect: 'toss/toss',
                protection: 'careful',
                protectedKarma: 'nie dotyczy',
                badKarma1: 7,
                badKarma2: 17,
                badKarma3: 15,
                badKarma4: 18,
                badKarma5: 10,
                badKarma6: 19,
                worseKarma1: 7,
                worseKarma2: 7,
                worseKarma3: 17,
                worseKarma4: 17,
                worseKarma5: 18,
                worseKarma6: 15,
                protectedDescription: 'nie dotyczy',
                badDescription1:
                    'Aura szaleństwa sprawiła, że słyszysz głosy, które każą Ci skoczyć w przepaść. Czujesz że pomieszało Ci się w głowie, ale posłusznie podchodzisz do krawędzi i skaczesz. Żeby Cię uratować wszyscy musieli przerwać swoje zajęcia i pospieszyć Ci na ratunek.',
                badDescription2:
                    'Aura szaleństwa sprawiła, że słyszysz głosy, które każą Ci skoczyć w przepaść. W ostatniej chwili oprzytomniałeś, lecz masz wrażenie że głosy w głowie pozostaną z Tobą na zawsze.',
                badDescription3:
                    'Aura szaleństwa sprawiła, że słyszysz głosy, które każą Ci skoczyć w przepaść. W ostatniej chwili oprzytomniałeś, lecz masz wrażenie że głosy w głowie pozostaną z Tobą na zawsze.',
                badDescription4:
                    'Górskie widoki są szalenie ciekawe. Co z tego, skoro podczas kontemplacji solidnie się wywróciłeś i porządnie potłukłeś.',
                badDescription5:
                    'Górskie widoki są szalenie ciekawe. Co z tego, skoro podczas kontemplacji solidnie się wywróciłeś i rozciąłeś sobie policzek o wystający kamień.',
                badDescription6:
                    'Góry jak góry: otoczenie jest monotonne do szaleństwa. Masz wrażenie że już tu kilka razy byłeś. Kręcisz się w kółko nie mogąc znaleźć właściwej drogi.',
                worseDescription1:
                    'Aura szaleństwa sprawiła, że słyszysz głosy, które każą Ci skoczyć w przepaść. Czujesz że pomieszało Ci się w głowie, ale posłusznie podchodzisz do krawędzi i skaczesz. Żeby Cię uratować wszyscy musieli przerwać swoje zajęcia i pospieszyć Ci na ratunek.',
                worseDescription2:
                    'Aura szaleństwa sprawiła, że słyszysz głosy, które każą Ci skoczyć w przepaść. Czujesz że pomieszało Ci się w głowie, ale posłusznie podchodzisz do krawędzi i skaczesz. Żeby Cię uratować wszyscy musieli przerwać swoje zajęcia i pospieszyć Ci na ratunek.',
                worseDescription3:
                    'Aura szaleństwa sprawiła że słyszysz głosy, które każą Ci skoczyć w przepaść. W ostatniej chwili oprzytomniałeś, lecz masz wrażenie że głosy w głowie pozostaną z Tobą na zawsze.',
                worseDescription4:
                    'Aura szaleństwa sprawiła że słyszysz głosy, które każą Ci skoczyć w przepaść. W ostatniej chwili oprzytomniałeś, lecz masz wrażenie że głosy w głowie pozostaną z Tobą na zawsze.',
                worseDescription5:
                    'Górskie widoki są szalenie ciekawe. Co z tego, skoro podczas kontemplacji solidnie się wywróciłeś i porządnie potłukłeś.',
                worseDescription6:
                    'Aura szaleństwa sprawiła że słyszysz głosy, które każą Ci skoczyć w przepaść. W ostatniej chwili oprzytomniałeś, lecz masz wrażenie że głosy w głowie pozostaną z Tobą na zawsze.',
            },
            Footbridge: {
                name: 'Kładka',
                effect: 'display',
                protection: 'helmet',
                protectedKarma: 1,
                badKarma1: 8,
                badKarma2: 'nie dotyczy',
                badKarma3: 'nie dotyczy',
                badKarma4: 'nie dotyczy',
                badKarma5: 'nie dotyczy',
                badKarma6: 'nie dotyczy',
                worseKarma1: 'nie dotyczy',
                worseKarma2: 'nie dotyczy',
                worseKarma3: 'nie dotyczy',
                worseKarma4: 'nie dotyczy',
                worseKarma5: 'nie dotyczy',
                worseKarma6: 'nie dotyczy',
                protectedDescription:
                    'Wpadasz do wody bo kładka jest niestabilna. Mieszka pod nią złośliwy troll. Spodobałeś mu się, więc nie zrobił Ci krzywdy i wychodzisz bezpiecznie na drugi brzeg.',
                badDescription1:
                    'Wpadasz do wody bo kładka jest niestabilna. Mieszka pod nią złośliwy troll. Nie spodobałeś mu się, więc zbił Cię maczugą - ledwo wyczołgujesz się na drugi brzeg.',
                badDescription2: 'nie dotyczy',
                badDescription3: 'nie dotyczy',
                badDescription4: 'nie dotyczy',
                badDescription5: 'nie dotyczy',
                badDescription6: 'nie dotyczy',
                worseDescription1: 'nie dotyczy',
                worseDescription2: 'nie dotyczy',
                worseDescription3: 'nie dotyczy',
                worseDescription4: 'nie dotyczy',
                worseDescription5: 'nie dotyczy',
                worseDescription6: 'nie dotyczy',
            },
            Avalanche: {
                name: 'Lawina kamieni',
                effect: 'display/toss',
                protection: 'helmet',
                protectedKarma: 1,
                badKarma1: 9,
                badKarma2: 9,
                badKarma3: 9,
                badKarma4: 9,
                badKarma5: 1,
                badKarma6: 1,
                worseKarma1: 'nie dotyczy',
                worseKarma2: 'nie dotyczy',
                worseKarma3: 'nie dotyczy',
                worseKarma4: 'nie dotyczy',
                worseKarma5: 'nie dotyczy',
                worseKarma6: 'nie dotyczy',
                protectedDescription:
                    'Na drogę którą idziesz sypią się kamienie, ale unikasz dotkliwego uderzenia którymkolwiek z nich.',
                badDescription1:
                    'Na drogę którą idziesz sypią się kamienie, a jeden z nich trafia Cię w głowę. Nie dość, że Cię zamroczyło, to jeszcze wyrasta Ci ogromny guz.',
                badDescription2:
                    'Na drogę którą idziesz sypią się kamienie, a jeden z nich trafia Cię w głowę. Nie dość, że Cię zamroczyło, to jeszcze wyrasta Ci ogromny guz.',
                badDescription3:
                    'Na drogę którą idziesz sypią się kamienie, a jeden z nich trafia Cię w głowę. Nie dość, że Cię zamroczyło, to jeszcze wyrasta Ci ogromny guz.',
                badDescription4:
                    'Na drogę którą idziesz sypią się kamienie, a jeden z nich trafia Cię w głowę. Nie dość, że Cię zamroczyło, to jeszcze wyrasta Ci ogromny guz.',
                badDescription5:
                    'Na drogę którą idziesz sypią się kamienie, ale unikasz dotkliwego uderzenia którymkolwiek z nich.',
                badDescription6:
                    'Na drogę którą idziesz sypią się kamienie, ale unikasz dotkliwego uderzenia którymkolwiek z nich.',
                worseDescription1: 'nie dotyczy',
                worseDescription2: 'nie dotyczy',
                worseDescription3: 'nie dotyczy',
                worseDescription4: 'nie dotyczy',
                worseDescription5: 'nie dotyczy',
                worseDescription6: 'nie dotyczy',
            },
            Generator: {
                name: 'Magiczny generator',
                effect: 'generator',
                protection: 'none',
                protectedKarma: 1,
                badKarma1: 5,
                badKarma2: 15,
                badKarma3: 20,
                badKarma4: 10,
                badKarma5: 21,
                badKarma6: 1,
                worseKarma1: 'nie dotyczy',
                worseKarma2: 'nie dotyczy',
                worseKarma3: 'nie dotyczy',
                worseKarma4: 'nie dotyczy',
                worseKarma5: 'nie dotyczy',
                worseKarma6: 'nie dotyczy',
                protectedDescription:
                    'Idziesz przez okolicę, po której rozsiane są nietypowe konstrukcje. Pewnie należą do magów - wszyscy wiedzą że oni budują takie dziwne rzeczy. Między słupami wesoło przeskakują magiczne iskierki. Nawet ci się podobają - w końcu rzadko masz okazję oglądać coś tak kolorowego.',
                badDescription1:
                    'Idziesz przez okolicę, po której rozsiane są nietypowe konstrukcje. Pewnie należą do magów - wszyscy wiedzą że oni budują takie dziwne rzeczy. Między słupami skrzą się przepięcia i sypią magiczne iskry. Jedna z nich Cię trafia i bezskutecznie próbujesz złapać oddech. Dusisz się ale po kilku sekundach wrażenie mija. Chyba nie ma się czym przejmować.',
                badDescription2:
                    'Idziesz przez okolicę, po której rozsiane są nietypowe konstrukcje. Pewnie należą do magów - wszyscy wiedzą że oni budują takie dziwne rzeczy. Między słupami skrzą się przepięcia i sypią magiczne iskry. Jedna z nich Cię trafia i nagle nie wiesz kim ani gdzie jesteś. Pamięć szwankuje ale po kilku sekundach wrażenie mija. Chyba nie ma się czym przejmować.',
                badDescription3:
                    'Idziesz przez okolicę, po której rozsiane są nietypowe konstrukcje. Pewnie należą do magów - wszyscy wiedzą że oni budują takie dziwne rzeczy. Między słupami skrzą się przepięcia i sypią magiczne iskry. Jedna z nich Cię trafia i pali brwi. Odrosną, więc chyba nie ma się czym przejmować.',
                badDescription4:
                    'Idziesz przez okolicę, po której rozsiane są nietypowe konstrukcje. Pewnie należą do magów - wszyscy wiedzą że oni budują takie dziwne rzeczy. Między słupami skrzą się przepięcia i sypią magiczne iskry. Jedna z nich Cię trafia i parzy w dłoń. Zagoi się, więc chyba nie ma się czym przejmować.',
                badDescription5:
                    'Idziesz przez okolicę, po której rozsiane są nietypowe konstrukcje. Pewnie należą do magów - wszyscy wiedzą że oni budują takie dziwne rzeczy. Między słupami skrzą się przepięcia i sypią magiczne iskry. Jedna z nich Cię trafia i odrzuca do tyłu. Spadłeś i trochę boli ale chyba nie ma się czym przejmować.',
                badDescription6:
                    'Idziesz przez okolicę, po której rozsiane są nietypowe konstrukcje. Pewnie należą do magów - wszyscy wiedzą że oni budują takie dziwne rzeczy. Między słupami skrzą się przepięcia i sypią magiczne iskry. Jedna z nich mija Cię o włos.',
                worseDescription1: 'nie dotyczy',
                worseDescription2: 'nie dotyczy',
                worseDescription3: 'nie dotyczy',
                worseDescription4: 'nie dotyczy',
                worseDescription5: 'nie dotyczy',
                worseDescription6: 'nie dotyczy',
            },
            Glade: {
                name: 'Myśliwska polana',
                effect: 'display',
                protection: 'breastplate',
                protectedKarma: 1,
                badKarma1: 10,
                badKarma2: 'nie dotyczy',
                badKarma3: 'nie dotyczy',
                badKarma4: 'nie dotyczy',
                badKarma5: 'nie dotyczy',
                badKarma6: 'nie dotyczy',
                worseKarma1: 'nie dotyczy',
                worseKarma2: 'nie dotyczy',
                worseKarma3: 'nie dotyczy',
                worseKarma4: 'nie dotyczy',
                worseKarma5: 'nie dotyczy',
                worseKarma6: 'nie dotyczy',
                protectedDescription:
                    'Mijasz polanę, na której polują myśliwi. Machają do Ciebie radośnie z daleka.',
                badDescription1:
                    'Mijasz polanę, na której polują myśliwi, a obok Ciebie właśnie przemyka lis. Słyszysz świst i czujesz ukłucie w pośladek - drasnęła Cię strzała przeznaczona dla lisa. Na szczęcie to tylko powierzchowna rana.',
                badDescription2: 'nie dotyczy',
                badDescription3: 'nie dotyczy',
                badDescription4: 'nie dotyczy',
                badDescription5: 'nie dotyczy',
                badDescription6: 'nie dotyczy',
                worseDescription1: 'nie dotyczy',
                worseDescription2: 'nie dotyczy',
                worseDescription3: 'nie dotyczy',
                worseDescription4: 'nie dotyczy',
                worseDescription5: 'nie dotyczy',
                worseDescription6: 'nie dotyczy',
            },
            Ruins: {
                name: 'Posępne ruiny',
                effect: 'display',
                protection: 'none',
                protectedKarma: 3,
                badKarma1: 3,
                badKarma2: 'nie dotyczy',
                badKarma3: 'nie dotyczy',
                badKarma4: 'nie dotyczy',
                badKarma5: 'nie dotyczy',
                badKarma6: 'nie dotyczy',
                worseKarma1: 'nie dotyczy',
                worseKarma2: 'nie dotyczy',
                worseKarma3: 'nie dotyczy',
                worseKarma4: 'nie dotyczy',
                worseKarma5: 'nie dotyczy',
                worseKarma6: 'nie dotyczy',
                protectedDescription:
                    'Wchodzisz pomiędzy budynki, szukając krótszej drogi do celu. Ostrożnie omijasz walącą się wieżę - kiedy nagle, nie wiadomo skąd - wyskakuje na Ciebie obszarpany dziad i wyklina Cię do piątego pokolenia wstecz! Nie potrafisz przebić się przez mur obelg. Jak niepyszny umykasz z tego miejsca. Jesteś roztrzęsiony jeszcze na długo po tym szokującym spotkaniu.',
                badDescription1:
                    'Wchodzisz pomiędzy budynki, szukając krótszej drogi do celu. Ostrożnie omijasz walącą się wieżę - kiedy nagle, nie wiadomo skąd - wyskakuje na Ciebie obszarpany dziad i wyklina Cię do piątego pokolenia wstecz! Nie potrafisz przebić się przez mur obelg. Jak niepyszny umykasz z tego miejsca. Jesteś roztrzęsiony jeszcze na długo po tym szokującym spotkaniu.',
                badDescription2: 'nie dotyczy',
                badDescription3: 'nie dotyczy',
                badDescription4: 'nie dotyczy',
                badDescription5: 'nie dotyczy',
                badDescription6: 'nie dotyczy',
                worseDescription1: 'nie dotyczy',
                worseDescription2: 'nie dotyczy',
                worseDescription3: 'nie dotyczy',
                worseDescription4: 'nie dotyczy',
                worseDescription5: 'nie dotyczy',
                worseDescription6: 'nie dotyczy',
            },
            Forest: {
                name: 'Posępny las',
                effect: 'display',
                protection: 'none',
                protectedKarma: 1,
                badKarma1: 1,
                badKarma2: 'nie dotyczy',
                badKarma3: 'nie dotyczy',
                badKarma4: 'nie dotyczy',
                badKarma5: 'nie dotyczy',
                badKarma6: 'nie dotyczy',
                worseKarma1: 'nie dotyczy',
                worseKarma2: 'nie dotyczy',
                worseKarma3: 'nie dotyczy',
                worseKarma4: 'nie dotyczy',
                worseKarma5: 'nie dotyczy',
                worseKarma6: 'nie dotyczy',
                protectedDescription:
                    'Jedziesz przez las, który wygląda jakby w każdej chwili mogło na Ciebie wyskoczyć coś strasznego. Na szczęście widzisz już prześwit między drzewami, jeszcze parę kroków i będziesz bezpieczny.',
                badDescription1:
                    'Jedziesz przez las, który wygląda jakby w każdej chwili mogło na Ciebie wyskoczyć coś strasznego. Na szczęście widzisz już prześwit między drzewami, jeszcze parę kroków i będziesz bezpieczny.',
                badDescription2: 'nie dotyczy',
                badDescription3: 'nie dotyczy',
                badDescription4: 'nie dotyczy',
                badDescription5: 'nie dotyczy',
                badDescription6: 'nie dotyczy',
                worseDescription1: 'nie dotyczy',
                worseDescription2: 'nie dotyczy',
                worseDescription3: 'nie dotyczy',
                worseDescription4: 'nie dotyczy',
                worseDescription5: 'nie dotyczy',
                worseDescription6: 'nie dotyczy',
            },
            Crossing: {
                name: 'Przeprawa wpław',
                effect: 'toss/toss',
                protection: 'breathing-spell',
                protectedKarma: 'nie dotyczy',
                badKarma1: 11,
                badKarma2: 11,
                badKarma3: 22,
                badKarma4: 22,
                badKarma5: 6,
                badKarma6: 5,
                worseKarma1: 11,
                worseKarma2: 11,
                worseKarma3: 11,
                worseKarma4: 11,
                worseKarma5: 22,
                worseKarma6: 6,
                protectedDescription: 'nie dotyczy',
                badDescription1:
                    'Rzeka, choć wygląda na spokojną, jest bardzo zdradziecka. Próbujesz jeszcze złapać ostatni łyk powietrza lecz nad tobą jest już tylko woda. Zachłystujesz się i toniesz a płuca zalane wodą palą Cię od wewnątrz. Ktoś na brzegu woła że trzeba Ci pomóc. Uratowali Cię, ale Twój organizm nie działa już tak jak kiedyś. Poruszasz się od tej pory nieco wolniej, potrzebujesz na wszystko więcej czasu.',
                badDescription2:
                    'Rzeka, choć wygląda na spokojną, jest bardzo zdradziecka. Próbujesz jeszcze złapać ostatni łyk powietrza lecz nad tobą jest już tylko woda. Zachłystujesz się i toniesz a płuca zalane wodą palą Cię od wewnątrz. Ktoś na brzegu woła że trzeba Ci pomóc. Uratowali Cię, ale Twój organizm nie działa już tak jak kiedyś. Poruszasz się od tej pory nieco wolniej, potrzebujesz na wszystko więcej czasu.',
                badDescription3:
                    'Rzeka, choć wygląda na spokojną, jest bardzo zdradziecka. Przepłynięcie jej to wyzwanie nawet dla takiego bohatera jak Ty. Krztusząc się wodą, ostatkiem sił dopływasz do brzegu. Czujesz że otarłeś się o śmierć i już nigdy nie będziesz tak beztroski jak kiedyś.',
                badDescription4:
                    'Rzeka, choć wygląda na spokojną, jest bardzo zdradziecka. Przepłynięcie jej to wyzwanie nawet dla takiego bohatera jak Ty. Krztusząc się wodą, ostatkiem sił dopływasz do brzegu. Czujesz że otarłeś się o śmierć i już nigdy nie będziesz tak beztroski jak kiedyś.',
                badDescription5:
                    'Rzeka, choć wygląda na spokojną, jest bardzo zdradziecka. Zachłystujesz się wodą niezliczoną ilość razy ale dopływasz na drugi brzeg. Długo łapiesz oddech i masz teraz troche charczący oddech. Myślałeś, że będzie łatwiej.',
                badDescription6:
                    'Rzeka, choć wygląda na spokojną, jest bardzo zdradziecka. Zachłystujesz się wodą niezliczoną ilość razy ale dopływasz na drugi brzeg. Myślałeś, że będzie łatwiej.',
                worseDescription1:
                    'Rzeka, choć wygląda na spokojną, jest bardzo zdradziecka. Próbujesz jeszcze złapać ostatni łyk powietrza lecz nad tobą jest już tylko woda. Zachłystujesz się i toniesz a płuca zalane wodą palą Cię od wewnątrz. Ktoś na brzegu woła że trzeba Ci pomóc. Uratowali Cię, ale Twój organizm nie działa już tak jak kiedyś. Poruszasz się od tej pory nieco wolniej, potrzebujesz na wszystko więcej czasu.',
                worseDescription2:
                    'Rzeka, choć wygląda na spokojną, jest bardzo zdradziecka. Próbujesz jeszcze złapać ostatni łyk powietrza lecz nad tobą jest już tylko woda. Zachłystujesz się i toniesz a płuca zalane wodą palą Cię od wewnątrz. Ktoś na brzegu woła że trzeba Ci pomóc. Uratowali Cię, ale Twój organizm nie działa już tak jak kiedyś. Poruszasz się od tej pory nieco wolniej, potrzebujesz na wszystko więcej czasu.',
                worseDescription3:
                    'Rzeka, choć wygląda na spokojną, jest bardzo zdradziecka. Próbujesz jeszcze złapać ostatni łyk powietrza lecz nad tobą jest już tylko woda. Zachłystujesz się i toniesz a płuca zalane wodą palą Cię od wewnątrz. Ktoś na brzegu woła że trzeba Ci pomóc. Uratowali Cię, ale Twój organizm nie działa już tak jak kiedyś. Poruszasz się od tej pory nieco wolniej, potrzebujesz na wszystko więcej czasu.',
                worseDescription4:
                    'Rzeka, choć wygląda na spokojną, jest bardzo zdradziecka. Próbujesz jeszcze złapać ostatni łyk powietrza lecz nad tobą jest już tylko woda. Zachłystujesz się i toniesz a płuca zalane wodą palą Cię od wewnątrz. Ktoś na brzegu woła że trzeba Ci pomóc. Uratowali Cię, ale Twój organizm nie działa już tak jak kiedyś. Poruszasz się od tej pory nieco wolniej, potrzebujesz na wszystko więcej czasu.',
                worseDescription5:
                    'Rzeka, choć wygląda na spokojną, jest bardzo zdradziecka. Przepłynięcie jej to wyzwanie nawet dla takiego bohatera jak ty. Krztusząc się wodą ostatkiem sił dopływasz do brzegu. Czujesz że otarłeś się o śmierć, już nigdy nie będziesz tak beztroski jak kiedyś.',
                worseDescription6:
                    'Rzeka, choć wygląda na spokojną, jest bardzo zdradziecka. Zachłystujesz się wodą niezliczoną ilość razy ale dopływasz na drugi brzeg. Długo łapiesz oddech i masz teraz troche charczący oddech. Myślałeś, że będzie łatwiej.',
            },
            Dragon: {
                name: 'Smok',
                effect: 'display',
                protection: 'antiflame-spell',
                protectedKarma: 4,
                badKarma1: 12,
                badKarma2: 'nie dotyczy',
                badKarma3: 'nie dotyczy',
                badKarma4: 'nie dotyczy',
                badKarma5: 'nie dotyczy',
                badKarma6: 'nie dotyczy',
                worseKarma1: 'nie dotyczy',
                worseKarma2: 'nie dotyczy',
                worseKarma3: 'nie dotyczy',
                worseKarma4: 'nie dotyczy',
                worseKarma5: 'nie dotyczy',
                worseKarma6: 'nie dotyczy',
                protectedDescription:
                    'Przechodzisz obok smoczej jaskini, budząc jej właściciela. Smok wypełza z pieczary i zieje ogniem na całą okolicę.',
                badDescription1:
                    'Przechodzisz obok smoczej jaskini, budząc jej właściciela. Smok wypełza z pieczary i zieje ogniem na całą okolicę.',
                badDescription2: 'nie dotyczy',
                badDescription3: 'nie dotyczy',
                badDescription4: 'nie dotyczy',
                badDescription5: 'nie dotyczy',
                badDescription6: 'nie dotyczy',
                worseDescription1: 'nie dotyczy',
                worseDescription2: 'nie dotyczy',
                worseDescription3: 'nie dotyczy',
                worseDescription4: 'nie dotyczy',
                worseDescription5: 'nie dotyczy',
                worseDescription6: 'nie dotyczy',
            },
            Appiary: {
                name: 'Stara pasieka',
                effect: 'toss/toss',
                protection: 'careful',
                protectedKarma: 'nie dotyczy',
                badKarma1: 13,
                badKarma2: 2,
                badKarma3: 2,
                badKarma4: 2,
                badKarma5: 20,
                badKarma6: 20,
                worseKarma1: 13,
                worseKarma2: 13,
                worseKarma3: 13,
                worseKarma4: 2,
                worseKarma5: 2,
                worseKarma6: 2,
                protectedDescription: 'nie dotyczy',
                badDescription1:
                    'Rozjuszyłeś zmutowane jadowite pszczoły. Byłeś najbliżej, więc oberwało Ci się najmocniej. Spuchnięty wyglądasz naprawdę okropnie, a dodatkowo pszczoły wyleciały z uli i żądlą każdego w Krainie. Od teraz, żeby nie umrzeć, każdy co rano będzie musiał przygotować czasochłonne antidotum.',
                badDescription2:
                    'Rozjuszyłeś pszczoły. Wyleciały z ula i żądlą Cię po całej głowie. Spuchnięty wyglądasz naprawdę okropnie.',
                badDescription3:
                    'Rozjuszyłeś pszczoły. Wyleciały z ula i żądlą Cię po całej głowie. Spuchnięty wyglądasz naprawdę okropnie.',
                badDescription4:
                    'Rozjuszyłeś pszczoły. Wyleciały z ula i żądlą Cię po całej głowie. Spuchnięty wyglądasz naprawdę okropnie.',
                badDescription5:
                    'Rozjuszyłeś pszczoły. Wyleciały z ula i żądlą Cię po całej głowie. Spuchnięty wyglądasz naprawdę okropnie.',
                badDescription6:
                    'Rozjuszyłeś pszczoły. Wyleciały z ula i żądlą Cię po całej głowie. Spuchnięty wyglądasz naprawdę okropnie.',
                worseDescription1:
                    'Rozjuszyłeś zmutowane jadowite pszczoły. Byłeś najbliżej, więc oberwało Ci się najmocniej. Spuchnięty wyglądasz naprawdę okropnie, a dodatkowo pszczoły wyleciały z uli i żądlą każdego w Krainie. Od teraz, żeby nie umrzeć, każdy co rano będzie musiał przygotować czasochłonne antidotum.',
                worseDescription2:
                    'Rozjuszyłeś zmutowane jadowite pszczoły. Byłeś najbliżej, więc oberwało Ci się najmocniej. Spuchnięty wyglądasz naprawdę okropnie, a dodatkowo pszczoły wyleciały z uli i żądlą każdego w Krainie. Od teraz, żeby nie umrzeć, każdy co rano będzie musiał przygotować czasochłonne antidotum.',
                worseDescription3:
                    'Rozjuszyłeś zmutowane jadowite pszczoły. Byłeś najbliżej, więc oberwało Ci się najmocniej. Spuchnięty wyglądasz naprawdę okropnie, a dodatkowo pszczoły wyleciały z uli i żądlą każdego w Krainie. Od teraz, żeby nie umrzeć, każdy co rano będzie musiał przygotować czasochłonne antidotum.',
                worseDescription4:
                    'Rozjuszyłeś pszczoły. Wyleciały z ula i żądlą Cię po całej głowie. Spuchnięty wyglądasz naprawdę okropnie.',
                worseDescription5:
                    'Rozjuszyłeś pszczoły. Wyleciały z ula i żądlą Cię po całej głowie. Spuchnięty wyglądasz naprawdę okropnie.',
                worseDescription6:
                    'Rozjuszyłeś pszczoły. Wyleciały z ula i żądlą Cię po całej głowie. Spuchnięty wyglądasz naprawdę okropnie.',
            },
            Slippery: {
                name: 'Ślisko',
                effect: 'display/toss',
                protection: 'careful',
                protectedKarma: 1,
                badKarma1: 14,
                badKarma2: 23,
                badKarma3: 23,
                badKarma4: 23,
                badKarma5: 1,
                badKarma6: 1,
                worseKarma1: 'nie dotyczy',
                worseKarma2: 'nie dotyczy',
                worseKarma3: 'nie dotyczy',
                worseKarma4: 'nie dotyczy',
                worseKarma5: 'nie dotyczy',
                worseKarma6: 'nie dotyczy',
                protectedDescription:
                    'Na drodze panują naprawdę niekorzystne warunki, ale szczęśliwie uniknąłeś poślizgnięcia się.',
                badDescription1:
                    'Na drodze panują naprawdę niekorzystne warunki i niestety się poślizgnąłeś. Nic poważnego się nie stało, zmarnowałeś tylko resztę swojego ruchu na pozbieranie się. Poza tym przez chwilę lekko kulejesz.',
                badDescription2:
                    'Na drodze panują naprawdę niekorzystne warunki i niestety się poślizgnąłeś. Nic poważnego się nie stało, lecz przez chwilę lekko kulejesz. Możesz iść pokazać to medykowi LUB zacisnąć zęby i jechać dalej -  w końcu to tylko stłuczenie.',
                badDescription3:
                    'Na drodze panują naprawdę niekorzystne warunki i niestety się poślizgnąłeś. Nic poważnego się nie stało, lecz przez chwilę lekko kulejesz. Możesz iść pokazać to medykowi LUB zacisnąć zęby i jechać dalej - w końcu to tylko stłuczenie.',
                badDescription4:
                    'Na drodze panują naprawdę niekorzystne warunki i niestety się poślizgnąłeś. Nic poważnego się nie stało, lecz przez chwilę lekko kulejesz. Możesz iść pokazać to medykowi LUB zacisnąć zęby i jechać dalej - w końcu to tylko stłuczenie.',
                badDescription5:
                    'Na drodze panują naprawdę niekorzystne warunki, ale szczęśliwie uniknąłeś poślizgnięcia się.',
                badDescription6:
                    'Na drodze panują naprawdę niekorzystne warunki, ale szczęśliwie uniknąłeś poślizgnięcia się.',
                worseDescription1: 'nie dotyczy',
                worseDescription2: 'nie dotyczy',
                worseDescription3: 'nie dotyczy',
                worseDescription4: 'nie dotyczy',
                worseDescription5: 'nie dotyczy',
                worseDescription6: 'nie dotyczy',
            },
            Wilderness: {
                name: 'Uroczysko',
                effect: 'display',
                protection: 'helmet',
                protectedKarma: 1,
                badKarma1: 15,
                badKarma2: 'nie dotyczy',
                badKarma3: 'nie dotyczy',
                badKarma4: 'nie dotyczy',
                badKarma5: 'nie dotyczy',
                badKarma6: 'nie dotyczy',
                worseKarma1: 'nie dotyczy',
                worseKarma2: 'nie dotyczy',
                worseKarma3: 'nie dotyczy',
                worseKarma4: 'nie dotyczy',
                worseKarma5: 'nie dotyczy',
                worseKarma6: 'nie dotyczy',
                protectedDescription:
                    'Podobno na uroczysku tańczą rusałki, przy okazji kusząc śpiewem przechodniów. To nawet mogłoby być ciekawie, lecz nie dociera do Ciebie żaden śpiew. Rozczarowany idziesz dalej.',
                badDescription1:
                    'Przechodząc przez uroczysko słyszysz wesoły i kuszący śpiew. Od odrobiny muzyki jeszcze nikt nie umarł. Idziesz dalej stwierdzając, że melodia przyczepiła się do Ciebie mocno i nie możesz przestać jej nucić.',
                badDescription2: 'nie dotyczy',
                badDescription3: 'nie dotyczy',
                badDescription4: 'nie dotyczy',
                badDescription5: 'nie dotyczy',
                badDescription6: 'nie dotyczy',
                worseDescription1: 'nie dotyczy',
                worseDescription2: 'nie dotyczy',
                worseDescription3: 'nie dotyczy',
                worseDescription4: 'nie dotyczy',
                worseDescription5: 'nie dotyczy',
                worseDescription6: 'nie dotyczy',
            },
            Volcano: {
                name: 'Wulkan',
                effect: 'toss/toss',
                protection: 'antiflame-spell',
                protectedKarma: 'nie dotyczy',
                badKarma1: 16,
                badKarma2: 16,
                badKarma3: 10,
                badKarma4: 10,
                badKarma5: 1,
                badKarma6: 1,
                worseKarma1: 24,
                worseKarma2: 24,
                worseKarma3: 16,
                worseKarma4: 16,
                worseKarma5: 10,
                worseKarma6: 10,
                protectedDescription: 'nie dotyczy',
                badDescription1:
                    'Ścieżka biegnie brzegiem krateru. Kiedy nią idziesz ziemia osuwa Ci się spod nóg, więc chroniąc się przed wpadnięciem do lawy, łapiesz się ostrej krawędzi głazu. Wygrzebałeś się ale poraniłeś sobie przy tym ręce, a na domiar złego pryskająca lawa wypaliła Ci dziurki na twarzy.',
                badDescription2:
                    'Ścieżka biegnie brzegiem krateru. Kiedy nią idziesz ziemia osuwa Ci się spod nóg, więc chroniąc się przed wpadnięciem do lawy, łapiesz się ostrej krawędzi głazu. Wygrzebałeś się ale poraniłeś sobie przy tym ręce, a na domiar złego pryskająca lawa wypaliła Ci dziurki na twarzy.',
                badDescription3:
                    'Ścieżka biegnie brzegiem krateru. Kiedy nią idziesz ziemia osuwa Ci się spod nóg, więc chroniąc się przed wpadnięciem do lawy, łapiesz się ostrej krawędzi głazu. Wygrzebałeś się, ale poraniłeś sobie przy tym ręce.',
                badDescription4:
                    'Ścieżka biegnie brzegiem krateru. Kiedy nią idziesz ziemia osuwa Ci się spod nóg, więc chroniąc się przed wpadnięciem do lawy, łapiesz się ostrej krawędzi głazu. Wygrzebałeś się, ale poraniłeś sobie przy tym ręce.',
                badDescription5:
                    'Ścieżka biegnie brzegiem krateru. Kiedy nią idziesz ziemia osuwa Ci się spod nóg, ale utrzymujesz równowagę.',
                badDescription6:
                    'Ścieżka biegnie brzegiem krateru. Kiedy nią idziesz ziemia osuwa Ci się spod nóg, ale utrzymujesz równowagę.',
                worseDescription1:
                    'Ścieżka biegnie brzegiem krateru. Kiedy nią idziesz ziemia osuwa Ci się spod nóg, więc chroniąc się przed wpadnięciem do lawy, łapiesz się ostrej krawędzi głazu. Wygrzebałeś się ale poraniłeś sobie przy tym ręce, a na domiar złego pryskająca lawa wypaliła Ci dziurki na twarzy.',
                worseDescription2:
                    'Ścieżka biegnie brzegiem krateru. Kiedy nią idziesz ziemia osuwa Ci się spod nóg, więc chroniąc się przed wpadnięciem do lawy, łapiesz się ostrej krawędzi głazu. Wygrzebałeś się ale poraniłeś sobie przy tym ręce, a na domiar złego pryskająca lawa wypaliła Ci dziurki na twarzy.',
                worseDescription3:
                    'Ścieżka biegnie brzegiem krateru. Kiedy nią idziesz ziemia osuwa Ci się spod nóg, więc chroniąc się przed wpadnięciem do lawy, łapiesz się ostrej krawędzi głazu. Wygrzebałeś się ale poraniłeś sobie przy tym ręce, a na domiar złego pryskająca lawa wypaliła Ci dziurki na twarzy.',
                worseDescription4:
                    'Ścieżka biegnie brzegiem krateru. Kiedy nią idziesz ziemia osuwa Ci się spod nóg, więc chroniąc się przed wpadnięciem do lawy, łapiesz się ostrej krawędzi głazu. Wygrzebałeś się ale poraniłeś sobie przy tym ręce, a na domiar złego pryskająca lawa wypaliła Ci dziurki na twarzy.',
                worseDescription5:
                    'Ścieżka biegnie brzegiem krateru. Kiedy nią idziesz ziemia osuwa Ci się spod nóg, więc chroniąc się przed wpadnięciem do lawy, łapiesz się ostrej krawędzi głazu. Wygrzebałeś się, ale poraniłeś sobie przy tym ręce.',
                worseDescription6:
                    'Ścieżka biegnie brzegiem krateru. Kiedy nią idziesz ziemia osuwa Ci się spod nóg, więc chroniąc się przed wpadnięciem do lawy, łapiesz się ostrej krawędzi głazu. Wygrzebałeś się, ale poraniłeś sobie przy tym ręce.',
            },
        },
    },
    en: {
        tutorial: {
            Vipers: {
                name: 'The Nest of Vipers',
                effect: 'display',
                protection: 'careful',
                protectedKarma: 1,
                badKarma1: 6,
                badKarma2: 'nie dotyczy',
                badKarma3: 'nie dotyczy',
                badKarma4: 'nie dotyczy',
                badKarma5: 'nie dotyczy',
                badKarma6: 'nie dotyczy',
                worseKarma1: 'nie dotyczy',
                worseKarma2: 'nie dotyczy',
                worseKarma3: 'nie dotyczy',
                worseKarma4: 'nie dotyczy',
                worseKarma5: 'nie dotyczy',
                worseKarma6: 'nie dotyczy',
                protectedDescription:
                    'You carefully plough through shrubbery. You hear some silent rustles in the nearby bushes, but everything grows silent when you majestically walk away.',
                badDescription1:
                    'You`re passing shrubbery singing to keep your spirits up, when all of a sudden you hear a spiteful hiss. Before you jump back, a viper gets you and bites your calves.',
                badDescription2: 'nie dotyczy',
                badDescription3: 'nie dotyczy',
                badDescription4: 'nie dotyczy',
                badDescription5: 'nie dotyczy',
                badDescription6: 'nie dotyczy',
                worseDescription1: 'nie dotyczy',
                worseDescription2: 'nie dotyczy',
                worseDescription3: 'nie dotyczy',
                worseDescription4: 'nie dotyczy',
                worseDescription5: 'nie dotyczy',
                worseDescription6: 'nie dotyczy',
            },
            Shrubbery: {
                name: 'Shrubbery',
                effect: 'display/toss',
                protection: 'none',
                protectedKarma: 1,
                badKarma1: 1,
                badKarma2: 1,
                badKarma3: 1,
                badKarma4: 1,
                badKarma5: 1,
                badKarma6: 1,
                worseKarma1: 'nie dotyczy',
                worseKarma2: 'nie dotyczy',
                worseKarma3: 'nie dotyczy',
                worseKarma4: 'nie dotyczy',
                worseKarma5: 'nie dotyczy',
                worseKarma6: 'nie dotyczy',
                protectedDescription:
                    'There are plenty of thorny bushes here which can easily hurt you, but you manage to avoid the trouble somehow.',
                badDescription1:
                    'There are plenty of thorny bushes here which can easily hurt you, but you manage to avoid the trouble somehow.',
                badDescription2:
                    'There are plenty of thorny bushes here which can easily hurt you, but you manage to avoid the trouble somehow.',
                badDescription3:
                    'There are plenty of thorny bushes here which can easily hurt you, but you manage to avoid the trouble somehow.',
                badDescription4:
                    'There are plenty of thorny bushes here which can easily hurt you, but you manage to avoid the trouble somehow.',
                badDescription5:
                    'There are plenty of thorny bushes here which can easily hurt you, but you manage to avoid the trouble somehow.',
                badDescription6:
                    'There are plenty of thorny bushes here which can easily hurt you, but you manage to avoid the trouble somehow.',
                worseDescription1: 'nie dotyczy',
                worseDescription2: 'nie dotyczy',
                worseDescription3: 'nie dotyczy',
                worseDescription4: 'nie dotyczy',
                worseDescription5: 'nie dotyczy',
                worseDescription6: 'nie dotyczy',
            },
        },
        game: {
            Village: {
                name: 'The Swamp Village',
                effect: 'display',
                protection: 'none',
                protectedKarma: 2,
                badKarma1: 2,
                badKarma2: 'nie dotyczy',
                badKarma3: 'nie dotyczy',
                badKarma4: 'nie dotyczy',
                badKarma5: 'nie dotyczy',
                badKarma6: 'nie dotyczy',
                worseKarma1: 'nie dotyczy',
                worseKarma2: 'nie dotyczy',
                worseKarma3: 'nie dotyczy',
                worseKarma4: 'nie dotyczy',
                worseKarma5: 'nie dotyczy',
                worseKarma6: 'nie dotyczy',
                protectedDescription:
                    'You`re travelling through a village inhabited by weird creatures. They throw stinky mud at strangers, just out of habit. They know their stuff - it`s extremely hard to get rid of the stench.',
                badDescription1:
                    'You`re travelling through a village inhabited by weird creatures. They throw stinky mud at strangers, just out of habit. They know their stuff - it`s extremely hard to get rid of the stench.',
                badDescription2: 'nie dotyczy',
                badDescription3: 'nie dotyczy',
                badDescription4: 'nie dotyczy',
                badDescription5: 'nie dotyczy',
                badDescription6: 'nie dotyczy',
                worseDescription1: 'nie dotyczy',
                worseDescription2: 'nie dotyczy',
                worseDescription3: 'nie dotyczy',
                worseDescription4: 'nie dotyczy',
                worseDescription5: 'nie dotyczy',
                worseDescription6: 'nie dotyczy',
            },
            Swamps: {
                name: 'The Swamps',
                effect: 'display',
                protection: 'breathing-spell',
                protectedKarma: 1,
                badKarma1: 5,
                badKarma2: 'nie dotyczy',
                badKarma3: 'nie dotyczy',
                badKarma4: 'nie dotyczy',
                badKarma5: 'nie dotyczy',
                badKarma6: 'nie dotyczy',
                worseKarma1: 'nie dotyczy',
                worseKarma2: 'nie dotyczy',
                worseKarma3: 'nie dotyczy',
                worseKarma4: 'nie dotyczy',
                worseKarma5: 'nie dotyczy',
                worseKarma6: 'nie dotyczy',
                protectedDescription:
                    'You`ve entered the swamps. The passage, though strenuous, is rather uneventful - only the air is hot and stuffy.',
                badDescription1:
                    'You`ve entered the swamps. The passage, though strenuous, is rather uneventful - only this toxic vapour irritates your throat. Not a big deal, but you seem to have contracted an odd cough.',
                badDescription2: 'nie dotyczy',
                badDescription3: 'nie dotyczy',
                badDescription4: 'nie dotyczy',
                badDescription5: 'nie dotyczy',
                badDescription6: 'nie dotyczy',
                worseDescription1: 'nie dotyczy',
                worseDescription2: 'nie dotyczy',
                worseDescription3: 'nie dotyczy',
                worseDescription4: 'nie dotyczy',
                worseDescription5: 'nie dotyczy',
                worseDescription6: 'nie dotyczy',
            },
            Mountains: {
                name: 'The Mountains of Madness',
                effect: 'toss/toss',
                protection: 'careful',
                protectedKarma: 'nie dotyczy',
                badKarma1: 7,
                badKarma2: 17,
                badKarma3: 15,
                badKarma4: 18,
                badKarma5: 10,
                badKarma6: 19,
                worseKarma1: 7,
                worseKarma2: 7,
                worseKarma3: 17,
                worseKarma4: 17,
                worseKarma5: 18,
                worseKarma6: 15,
                protectedDescription: 'nie dotyczy',
                badDescription1:
                    'The air of madness makes you hear some voices telling you to jump into the abyss. You feel totally confused, but, obediently, you approach the edge and jump. All your companions had to abandon their tasks and rush to save you.',
                badDescription2:
                    'The air of madness makes you hear some voices telling you to jump into the abyss. You came to your senses in the nick of time, but you have an uneasy feeling that the voices will stay in your head forever.',
                badDescription3:
                    'The air of madness makes you hear some voices telling you to jump into the abyss. You came to your senses in the nick of time, but you have an uneasy feeling that the voices will stay in your head forever.',
                badDescription4:
                    'Mountain views are unforgettable. What of it, if during the contemplation you took a tumble and now you`re all in cuts and bruises.',
                badDescription5:
                    'Mountain views are unforgettable. What of it, if during the contemplation you took a tumble and a protruding rock cut your cheek.',
                badDescription6:
                    'The mountains are nothing special and their monotony drives you crazy. You feel that you`ve been here before. You`re going in circles, unable to find the right path.',
                worseDescription1:
                    'The air of madness makes you hear some voices telling you to jump into the abyss. You feel totally confused, but, obediently, you approach the edge and jump. All your companions had to abandon their tasks and rush to save you.',
                worseDescription2:
                    'The air of madness makes you hear some voices telling you to jump into the abyss. You feel totally confused, but, obediently, you approach the edge and jump. All your companions had to abandon their tasks and rush to save you.',
                worseDescription3:
                    'The air of madness makes you hear some voices telling you to jump into the abyss. You came to your senses in the nick of time, but you have an uneasy feeling that the voices will stay in your head forever.',
                worseDescription4:
                    'The air of madness makes you hear some voices telling you to jump into the abyss. You came to your senses in the nick of time, but you have an uneasy feeling that the voices will stay in your head forever.',
                worseDescription5:
                    'Mountain views are unforgettable. What of it, if during the contemplation you took a tumble and now you`re all in cuts and bruises.',
                worseDescription6:
                    'The air of madness makes you hear some voices telling you to jump into the abyss. You came to your senses in the nick of time, but you have an uneasy feeling that the voices will stay in your head forever.',
            },
            Footbridge: {
                name: 'The Footbridge',
                effect: 'display',
                protection: 'helmet',
                protectedKarma: 1,
                badKarma1: 8,
                badKarma2: 'nie dotyczy',
                badKarma3: 'nie dotyczy',
                badKarma4: 'nie dotyczy',
                badKarma5: 'nie dotyczy',
                badKarma6: 'nie dotyczy',
                worseKarma1: 'nie dotyczy',
                worseKarma2: 'nie dotyczy',
                worseKarma3: 'nie dotyczy',
                worseKarma4: 'nie dotyczy',
                worseKarma5: 'nie dotyczy',
                worseKarma6: 'nie dotyczy',
                protectedDescription:
                    'You fall into the water because the footbridge wobbles. The nasty troll dwelling under the bridge took a fancy to you therefore unhurt you safely get to the other side.',
                badDescription1:
                    'You fall into the water because the footbridge wobbles. The nasty troll dwelling under the bridge doesn`t fancy you too much and hits you on the head with his bludgeon - you hardly crawl to the other side.',
                badDescription2: 'nie dotyczy',
                badDescription3: 'nie dotyczy',
                badDescription4: 'nie dotyczy',
                badDescription5: 'nie dotyczy',
                badDescription6: 'nie dotyczy',
                worseDescription1: 'nie dotyczy',
                worseDescription2: 'nie dotyczy',
                worseDescription3: 'nie dotyczy',
                worseDescription4: 'nie dotyczy',
                worseDescription5: 'nie dotyczy',
                worseDescription6: 'nie dotyczy',
            },
            Avalanche: {
                name: 'The Stony Avalanche',
                effect: 'display/toss',
                protection: 'helmet',
                protectedKarma: 1,
                badKarma1: 9,
                badKarma2: 9,
                badKarma3: 9,
                badKarma4: 9,
                badKarma5: 1,
                badKarma6: 1,
                worseKarma1: 'nie dotyczy',
                worseKarma2: 'nie dotyczy',
                worseKarma3: 'nie dotyczy',
                worseKarma4: 'nie dotyczy',
                worseKarma5: 'nie dotyczy',
                worseKarma6: 'nie dotyczy',
                protectedDescription:
                    'Stones fall on your path but you avoid any serious hit.',
                badDescription1:
                    'Stones fall on your path and one hits your head. Not only did you almost faint, but you also got a tremendous bump.',
                badDescription2:
                    'Stones fall on your path and one hits your head. Not only did you almost faint, but you also got a tremendous bump.',
                badDescription3:
                    'Stones fall on your path and one hits your head. Not only did you almost faint, but you also got a tremendous bump.',
                badDescription4:
                    'Stones fall on your path and one hits your head. Not only did you almost faint, but you also got a tremendous bump.',
                badDescription5:
                    'Stones fall on your path but you avoid any serious hit.',
                badDescription6:
                    'Stones fall on your path but you avoid any serious hit.',
                worseDescription1: 'nie dotyczy',
                worseDescription2: 'nie dotyczy',
                worseDescription3: 'nie dotyczy',
                worseDescription4: 'nie dotyczy',
                worseDescription5: 'nie dotyczy',
                worseDescription6: 'nie dotyczy',
            },
            Generator: {
                name: 'A Magic Generator',
                effect: 'generator',
                protection: 'none',
                protectedKarma: 1,
                badKarma1: 5,
                badKarma2: 15,
                badKarma3: 20,
                badKarma4: 10,
                badKarma5: 21,
                badKarma6: 1,
                worseKarma1: 'nie dotyczy',
                worseKarma2: 'nie dotyczy',
                worseKarma3: 'nie dotyczy',
                worseKarma4: 'nie dotyczy',
                worseKarma5: 'nie dotyczy',
                worseKarma6: 'nie dotyczy',
                protectedDescription:
                    'Idziesz przez okolicę, po której rozsiane są nietypowe konstrukcje. Pewnie należą do magów - wszyscy wiedzą że oni budują takie dziwne rzeczy. Między słupami wesoło przeskakują magiczne iskierki. Nawet ci się podobają - w końcu rzadko masz okazję oglądać coś tak kolorowego.',
                badDescription1:
                    'You`re walking through a territory infested with weird constructions. They might belong to magicians, it`s common knowledge that they build such odd things. Among the poles there glitter flashes and magical sparks. You`re hit by one of those and almost suffocate, desperately fighting to catch your breath, but after a few seconds you`re OK. It seems there`s nothing to worry about.',
                badDescription2:
                    'You`re walking through a territory infested with weird constructions. They might belong to magicians, it`s common knowledge that they build such odd things. Among the poles there glitter flashes and magical sparks. You`re hit by one of those and suddenly you`re memory is gone, you don`t know where you are, who you are...but after a few seconds you`re OK. It seems there`s nothing to worry about.',
                badDescription3:
                    'You`re walking through a territory infested with weird constructions. They might belong to magicians, it`s common knowledge that they build such odd things. Among the poles there glitter flashes and magical sparks. You`re hit by one of those and it burns your eyebrows. They`ll grow again so it seems there`s nothing to worry about.',
                badDescription4:
                    'You`re walking through a territory infested with weird constructions. They might belong to magicians, it`s common knowledge that they build such odd things. Among the poles there glitter flashes and magical sparks. They narrowly miss you, only one hits your hand and burns it slightly. It will heal so it seems thare`s nothing to worry about.',
                badDescription5:
                    'You`re walking through a territory infested with weird constructions. They might belong to magicians, it`s common knowledge that they build such odd things. Among the poles there glitter flashes and magical sparks. You`re hit by one of those and pushed backwards. You fell and it hurts a bit, but it seems there`s nothing to worry about.',
                badDescription6:
                    'You`re walking through a territory infested with weird constructions. They might belong to magicians, it`s common knowledge that they build such odd things. Among the poles merrily glitter magical sparks. You even like them - hardly ever do you have a chance to watch something so colourful.',
                worseDescription1: 'nie dotyczy',
                worseDescription2: 'nie dotyczy',
                worseDescription3: 'nie dotyczy',
                worseDescription4: 'nie dotyczy',
                worseDescription5: 'nie dotyczy',
                worseDescription6: 'nie dotyczy',
            },
            Glade: {
                name: 'The Hunters` Glade',
                effect: 'display',
                protection: 'breastplate',
                protectedKarma: 1,
                badKarma1: 10,
                badKarma2: 'nie dotyczy',
                badKarma3: 'nie dotyczy',
                badKarma4: 'nie dotyczy',
                badKarma5: 'nie dotyczy',
                badKarma6: 'nie dotyczy',
                worseKarma1: 'nie dotyczy',
                worseKarma2: 'nie dotyczy',
                worseKarma3: 'nie dotyczy',
                worseKarma4: 'nie dotyczy',
                worseKarma5: 'nie dotyczy',
                worseKarma6: 'nie dotyczy',
                protectedDescription:
                    'You`re passing a glade where a few guys are hunting. They wave at you cheerfully.',
                badDescription1:
                    'You`re passing a glade where a few guys are hunting. A fox flits around the bushes. Suddenly you hear a zip and something prickles your buttock. You were grazed by an arrow intended for the fox. Fortunately, it`s merely a scratch.',
                badDescription2: 'nie dotyczy',
                badDescription3: 'nie dotyczy',
                badDescription4: 'nie dotyczy',
                badDescription5: 'nie dotyczy',
                badDescription6: 'nie dotyczy',
                worseDescription1: 'nie dotyczy',
                worseDescription2: 'nie dotyczy',
                worseDescription3: 'nie dotyczy',
                worseDescription4: 'nie dotyczy',
                worseDescription5: 'nie dotyczy',
                worseDescription6: 'nie dotyczy',
            },
            Ruins: {
                name: 'The Grim Ruins',
                effect: 'display',
                protection: 'none',
                protectedKarma: 3,
                badKarma1: 3,
                badKarma2: 'nie dotyczy',
                badKarma3: 'nie dotyczy',
                badKarma4: 'nie dotyczy',
                badKarma5: 'nie dotyczy',
                badKarma6: 'nie dotyczy',
                worseKarma1: 'nie dotyczy',
                worseKarma2: 'nie dotyczy',
                worseKarma3: 'nie dotyczy',
                worseKarma4: 'nie dotyczy',
                worseKarma5: 'nie dotyczy',
                worseKarma6: 'nie dotyczy',
                protectedDescription:
                    'You`re trudging among the buildings, seeking a shortcut. You carefully keep clear of a ruined tower, but suddenly, out of thin air, a ragged trump springs from the ground and swears at you like a trooper. You can`t digest this stream of abuse. You flee, absolutely crestfallen, but you`ll be shaking long after this shocking encounter.',
                badDescription1:
                    'You`re trudging among the buildings, seeking a shortcut. You carefully keep clear of a ruined tower, but suddenly, out of thin air, a ragged trump springs from the ground and swears at you like a trooper. You can`t digest this stream of abuse. You flee, absolutely crestfallen, but you`ll be shaking long after this shocking encounter.',
                badDescription2: 'nie dotyczy',
                badDescription3: 'nie dotyczy',
                badDescription4: 'nie dotyczy',
                badDescription5: 'nie dotyczy',
                badDescription6: 'nie dotyczy',
                worseDescription1: 'nie dotyczy',
                worseDescription2: 'nie dotyczy',
                worseDescription3: 'nie dotyczy',
                worseDescription4: 'nie dotyczy',
                worseDescription5: 'nie dotyczy',
                worseDescription6: 'nie dotyczy',
            },
            Forest: {
                name: 'The Grim Forest',
                effect: 'display',
                protection: 'none',
                protectedKarma: 1,
                badKarma1: 1,
                badKarma2: 'nie dotyczy',
                badKarma3: 'nie dotyczy',
                badKarma4: 'nie dotyczy',
                badKarma5: 'nie dotyczy',
                badKarma6: 'nie dotyczy',
                worseKarma1: 'nie dotyczy',
                worseKarma2: 'nie dotyczy',
                worseKarma3: 'nie dotyczy',
                worseKarma4: 'nie dotyczy',
                worseKarma5: 'nie dotyczy',
                worseKarma6: 'nie dotyczy',
                protectedDescription:
                    'You`re travelling through a forest which looks as if something horrible might jump at you any moment. Luckily, you can already see some light amongst the trees, a couple of steps and you`ll be safe.',
                badDescription1:
                    'You`re travelling through a forest which looks as if something horrible might jump at you any moment. Luckily, you can already see some light amongst the trees, a couple of steps and you`ll be safe.',
                badDescription2: 'nie dotyczy',
                badDescription3: 'nie dotyczy',
                badDescription4: 'nie dotyczy',
                badDescription5: 'nie dotyczy',
                badDescription6: 'nie dotyczy',
                worseDescription1: 'nie dotyczy',
                worseDescription2: 'nie dotyczy',
                worseDescription3: 'nie dotyczy',
                worseDescription4: 'nie dotyczy',
                worseDescription5: 'nie dotyczy',
                worseDescription6: 'nie dotyczy',
            },
            Crossing: {
                name: 'Deep-Water Crossing',
                effect: 'toss/toss',
                protection: 'breathing-spell',
                protectedKarma: 'nie dotyczy',
                badKarma1: 11,
                badKarma2: 11,
                badKarma3: 22,
                badKarma4: 22,
                badKarma5: 6,
                badKarma6: 5,
                worseKarma1: 11,
                worseKarma2: 11,
                worseKarma3: 11,
                worseKarma4: 11,
                worseKarma5: 22,
                worseKarma6: 6,
                protectedDescription: 'nie dotyczy',
                badDescription1:
                    'The river, seemingly peaceful, is damn treacherous.Struggling to catch the last breath, you`re already covered with water. You choke and start drowning, your lungs, filled with water, feel like burning. Someone at the river bank screams that you`re in need of help. They saved you, but your body no longer functions as it used to. You move much slower and need more time to do anything.',
                badDescription2:
                    'The river, seemingly peaceful, is damn treacherous.Struggling to catch the last breath, you`re already covered with water. You choke and start drowning, your lungs, filled with water, feel like burning. Someone at the river bank screams that you`re in need of help. They saved you, but your body no longer functions as it used to. You move much slower and need more time to do anything.',
                badDescription3:
                    'The river, seemingly peaceful, is damn treacherous. Swimming across means a challenge even for such a hero like you. It takes every last ounce of strength you possess to get, choking, to the other side. You`ve been at death`s door and you`ll never be as carefree as before.',
                badDescription4:
                    'The river, seemingly peaceful, is damn treacherous. Swimming across means a challenge even for such a hero like you. It takes every last ounce of strength you possess to get, choking, to the other side. You`ve been at death`s door and you`ll never be as carefree as before.',
                badDescription5:
                    'The river, seemingly peaceful, is damn treacherous. You choke countless times but manage to get to the other side. You gasp for breath for quite a long time and begin to wheeze a bit. You thought it would be easier.',
                badDescription6:
                    'The river, seemingly peaceful, is damn treacherous. You choke countless times but manage to get to the other side. You thought it would be easier.',
                worseDescription1:
                    'The river, seemingly peaceful, is damn treacherous.Struggling to catch the last breath, you`re already covered with water. You choke and start drowning, your lungs, filled with water, feel like burning. Someone at the river bank screams that you`re in need of help. They saved you, but your body no longer functions as it used to. You move much slower and need more time to do anything.',
                worseDescription2:
                    'The river, seemingly peaceful, is damn treacherous.Struggling to catch the last breath, you`re already covered with water. You choke and start drowning, your lungs, filled with water, feel like burning. Someone at the river bank screams that you`re in need of help. They saved you, but your body no longer functions as it used to. You move much slower and need more time to do anything.',
                worseDescription3:
                    'The river, seemingly peaceful, is damn treacherous.Struggling to catch the last breath, you`re already covered with water. You choke and start drowning, your lungs, filled with water, feel like burning. Someone at the river bank screams that you`re in need of help. They saved you, but your body no longer functions as it used to. You move much slower and need more time to do anything.',
                worseDescription4:
                    'The river, seemingly peaceful, is damn treacherous.Struggling to catch the last breath, you`re already covered with water. You choke and start drowning, your lungs, filled with water, feel like burning. Someone at the river bank screams that you`re in need of help. They saved you, but your body no longer functions as it used to. You move much slower and need more time to do anything.',
                worseDescription5:
                    'The river, seemingly peaceful, is damn treacherous. Swimming across means a challenge even for such a hero like you. It takes every last ounce of strength you possess to get, choking, to the other side. You`ve been at death`s door and you`ll never be as carefree as before.',
                worseDescription6:
                    'The river, seemingly peaceful, is damn treacherous. You choke countless times but manage to get to the other side. You gasp for breath for quite a long time and begin to wheeze a bit. You thought it would be easier.',
            },
            Dragon: {
                name: 'A Dragon',
                effect: 'display',
                protection: 'antiflame-spell',
                protectedKarma: 4,
                badKarma1: 12,
                badKarma2: 'nie dotyczy',
                badKarma3: 'nie dotyczy',
                badKarma4: 'nie dotyczy',
                badKarma5: 'nie dotyczy',
                badKarma6: 'nie dotyczy',
                worseKarma1: 'nie dotyczy',
                worseKarma2: 'nie dotyczy',
                worseKarma3: 'nie dotyczy',
                worseKarma4: 'nie dotyczy',
                worseKarma5: 'nie dotyczy',
                worseKarma6: 'nie dotyczy',
                protectedDescription:
                    'Walking by a Dragon`s den, you wake up its owner. The Dragon crawls out and breathes fire round the whole zone.',
                badDescription1:
                    'Walking by a Dragon`s den, you wake up its owner. The Dragon crawls out and breathes fire round the whole zone.',
                badDescription2: 'nie dotyczy',
                badDescription3: 'nie dotyczy',
                badDescription4: 'nie dotyczy',
                badDescription5: 'nie dotyczy',
                badDescription6: 'nie dotyczy',
                worseDescription1: 'nie dotyczy',
                worseDescription2: 'nie dotyczy',
                worseDescription3: 'nie dotyczy',
                worseDescription4: 'nie dotyczy',
                worseDescription5: 'nie dotyczy',
                worseDescription6: 'nie dotyczy',
            },
            Appiary: {
                name: 'The Old Apiary',
                effect: 'toss/toss',
                protection: 'careful',
                protectedKarma: 'nie dotyczy',
                badKarma1: 13,
                badKarma2: 2,
                badKarma3: 2,
                badKarma4: 2,
                badKarma5: 20,
                badKarma6: 20,
                worseKarma1: 13,
                worseKarma2: 13,
                worseKarma3: 13,
                worseKarma4: 2,
                worseKarma5: 2,
                worseKarma6: 2,
                protectedDescription: 'nie dotyczy',
                badDescription1:
                    'You`ve annoyed the poisonous mutant bees. You were standing the closest, so you suffered the most. Swollen, you look really terrible, what`s more, the bees left their beehives and now sting eveybody in the realm. From now on, to stay alive, everyone will have to prepare a complicated antidote first thing in the morning.',
                badDescription2:
                    'You`ve annoyed the bees. They swarm out of their beehive and sting you on the head. Swollen, you look really terrible.',
                badDescription3:
                    'You`ve annoyed the bees. They swarm out of their beehive and sting you on the head. Swollen, you look really terrible.',
                badDescription4:
                    'You`ve annoyed the bees. They swarm out of their beehive and sting you on the head. Swollen, you look really terrible.',
                badDescription5:
                    'You`ve annoyed the bees. They swarm out of their beehive and sting you on the head. Swollen, you look really terrible.',
                badDescription6:
                    'You`ve annoyed the bees. They swarm out of their beehive and sting you on the head. Swollen, you look really terrible.',
                worseDescription1:
                    'You`ve annoyed the poisonous mutant bees. You were standing the closest, so you suffered the most. Swollen, you look really terrible, what`s more, the bees left their beehives and now sting eveybody in the realm. From now on, to stay alive, everyone will have to prepare a complicated antidote first thing in the morning.',
                worseDescription2:
                    'You`ve annoyed the poisonous mutant bees. You were standing the closest, so you suffered the most. Swollen, you look really terrible, what`s more, the bees left their beehives and now sting eveybody in the realm. From now on, to stay alive, everyone will have to prepare a complicated antidote first thing in the morning.',
                worseDescription3:
                    'You`ve annoyed the poisonous mutant bees. You were standing the closest, so you suffered the most. Swollen, you look really terrible, what`s more, the bees left their beehives and now sting eveybody in the realm. From now on, to stay alive, everyone will have to prepare a complicated antidote first thing in the morning.',
                worseDescription4:
                    'You`ve annoyed the bees. They swarm out of their beehive and sting you on the head. Swollen, you look really terrible.',
                worseDescription5:
                    'You`ve annoyed the bees. They swarm out of their beehive and sting you on the head. Swollen, you look really terrible.',
                worseDescription6:
                    'You`ve annoyed the bees. They swarm out of their beehive and sting you on the head. Swollen, you look really terrible.',
            },
            Slippery: {
                name: 'Slippery Patches',
                effect: 'display/toss',
                protection: 'careful',
                protectedKarma: 1,
                badKarma1: 14,
                badKarma2: 23,
                badKarma3: 23,
                badKarma4: 23,
                badKarma5: 1,
                badKarma6: 1,
                worseKarma1: 'nie dotyczy',
                worseKarma2: 'nie dotyczy',
                worseKarma3: 'nie dotyczy',
                worseKarma4: 'nie dotyczy',
                worseKarma5: 'nie dotyczy',
                worseKarma6: 'nie dotyczy',
                protectedDescription:
                    'The road is extremely slippery but, luckily, you avoided falling.',
                badDescription1:
                    'The road is extremely slippery and, unfortunately, you fell. Nothing serious, you just wasted the rest of your move to pull yourself together and you limp for a moment.',
                badDescription2:
                    'The road is extremely slippery and, unfortunately, you fell. Nothing serious, but you limp for a moment. You can either go to a physician OR grit your teeth and go on - it`s only a little contusion, after all.',
                badDescription3:
                    'The road is extremely slippery and, unfortunately, you fell. Nothing serious, but you limp for a moment. You can either go to a physician OR grit your teeth and go on - it`s only a little contusion, after all.',
                badDescription4:
                    'The road is extremely slippery and, unfortunately, you fell. Nothing serious, but you limp for a moment. You can either go to a physician OR grit your teeth and go on - it`s only a little contusion, after all.',
                badDescription5:
                    'The road is extremely slippery but, luckily, you avoided falling.',
                badDescription6:
                    'The road is extremely slippery but, luckily, you avoided falling.',
                worseDescription1: 'nie dotyczy',
                worseDescription2: 'nie dotyczy',
                worseDescription3: 'nie dotyczy',
                worseDescription4: 'nie dotyczy',
                worseDescription5: 'nie dotyczy',
                worseDescription6: 'nie dotyczy',
            },
            Wilderness: {
                name: 'The Wilderness',
                effect: 'display',
                protection: 'helmet',
                protectedKarma: 1,
                badKarma1: 15,
                badKarma2: 'nie dotyczy',
                badKarma3: 'nie dotyczy',
                badKarma4: 'nie dotyczy',
                badKarma5: 'nie dotyczy',
                badKarma6: 'nie dotyczy',
                worseKarma1: 'nie dotyczy',
                worseKarma2: 'nie dotyczy',
                worseKarma3: 'nie dotyczy',
                worseKarma4: 'nie dotyczy',
                worseKarma5: 'nie dotyczy',
                worseKarma6: 'nie dotyczy',
                protectedDescription:
                    'Supposedly, there are naiads dancing here, tempting the travellers with their songs. It even sounds attractive but you can hear no singing whatsoever. You go on, bitterly disappointed.',
                badDescription1:
                    'Crossing the wilderness you hear tempting, joyful singing. A little bit of music never hurt anyone. You go on and realise that the melody sank deeply in your memory and you can`t stop yourself humming.',
                badDescription2: 'nie dotyczy',
                badDescription3: 'nie dotyczy',
                badDescription4: 'nie dotyczy',
                badDescription5: 'nie dotyczy',
                badDescription6: 'nie dotyczy',
                worseDescription1: 'nie dotyczy',
                worseDescription2: 'nie dotyczy',
                worseDescription3: 'nie dotyczy',
                worseDescription4: 'nie dotyczy',
                worseDescription5: 'nie dotyczy',
                worseDescription6: 'nie dotyczy',
            },
            Volcano: {
                name: 'The Volcano',
                effect: 'toss/toss',
                protection: 'antiflame-spell',
                protectedKarma: 'nie dotyczy',
                badKarma1: 16,
                badKarma2: 16,
                badKarma3: 10,
                badKarma4: 10,
                badKarma5: 1,
                badKarma6: 1,
                worseKarma1: 24,
                worseKarma2: 24,
                worseKarma3: 16,
                worseKarma4: 16,
                worseKarma5: 10,
                worseKarma6: 10,
                protectedDescription: 'nie dotyczy',
                badDescription1:
                    'Your path leads along the rim of the crater. The ground starts crumbling under your feet, you catch a sharp edge of a rock so as not to fall into the lava. You avoided a fall, but hurt your hands, what`s more, spattering lava burnt a few holes in your face.',
                badDescription2:
                    'Your path leads along the rim of the crater. The ground starts crumbling under your feet, you catch a sharp edge of a rock so as not to fall into the lava. You avoided a fall, but hurt your hands, what`s more, spattering lava burnt a few holes in your face.',
                badDescription3:
                    'Your path leads along the rim of the crater. The ground starts crumbling under your feet, you catch a sharp edge of a rock so as not to fall into the lava. You avoided a fall, but hurt your hands.',
                badDescription4:
                    'Your path leads along the rim of the crater. The ground starts crumbling under your feet, you catch a sharp edge of a rock so as not to fall into the lava. You avoided a fall, but hurt your hands.',
                badDescription5:
                    'Your path leads along the rim of the crater. The ground starts crumbling under your feet, but you keep your balance.',
                badDescription6:
                    'Your path leads along the rim of the crater. The ground starts crumbling under your feet, but you keep your balance.',
                worseDescription1:
                    'Your path leads along the rim of the crater. The ground starts crumbling under your feet, you catch a sharp edge of a rock so as not to fall into the lava. You avoided a fall, but hurt your hands, what`s more, spattering lava burnt a few holes in your face.',
                worseDescription2:
                    'Your path leads along the rim of the crater. The ground starts crumbling under your feet, you catch a sharp edge of a rock so as not to fall into the lava. You avoided a fall, but hurt your hands, what`s more, spattering lava burnt a few holes in your face.',
                worseDescription3:
                    'Your path leads along the rim of the crater. The ground starts crumbling under your feet, you catch a sharp edge of a rock so as not to fall into the lava. You avoided a fall, but hurt your hands, what`s more, spattering lava burnt a few holes in your face.',
                worseDescription4:
                    'Your path leads along the rim of the crater. The ground starts crumbling under your feet, you catch a sharp edge of a rock so as not to fall into the lava. You avoided a fall, but hurt your hands, what`s more, spattering lava burnt a few holes in your face.',
                worseDescription5:
                    'Your path leads along the rim of the crater. The ground starts crumbling under your feet, you catch a sharp edge of a rock so as not to fall into the lava. You avoided a fall, but hurt your hands.',
                worseDescription6:
                    'Your path leads along the rim of the crater. The ground starts crumbling under your feet, you catch a sharp edge of a rock so as not to fall into the lava. You avoided a fall, but hurt your hands.',
            },
        },
    },
};

export const messages = {
    pl: {
        wronggame: {
            title: 'Gra nie istnieje',
            message:
                'Taka gra nie istnieje, zaloguj się do panelu trenera i spróbuj jeszcze raz',
            event: 'wronggame',
        },
        wrongcode: {
            title: 'Konflikt urządzeń',
            message:
                'Prawdopodobnie gra została uruchomiona na innym urządzeniu. Jeśli chcesz ją przenieść na to urządzenie, zaloguj się do panelu trenera i użyj odpowiedniej opcji',
            event: 'wronggcode',
        },
        expired: {
            title: 'Gra zakończona',
            message:
                'Prawdopodobnie minął czas na grę. Zaloguj się do panelu trenera i sprawdź swoją listę gier',
            event: 'wronggcode',
        },
        missingcredentials: {
            title: 'Brak danych gry',
            message:
                'Prawdopodobnie próbujesz uruchomić gre przez przeklejenie linka na inne urządzenie. Zaloguj się do panelu trenera i uruchom grę',
            event: 'wronggcode',
        },
        diceTitle: 'Rzuć jedną kością i wybierz wynik',
        diceOr: 'LUB',
    },
    en: {
        wronggame: {
            title: 'Gem does not exist',
            message:
                'There is no such game, please login to your trainer panel and check your games list',
            event: 'wronggame',
        },
        wrongcode: {
            title: 'Device conflict detected',
            message:
                'Most likely reason: the same game is running on another device. If you need to transfer this game to currnt device, please login to trainer panel and do that.',
            event: 'wronggcode',
        },
        expired: {
            title: 'Game Finished',
            message:
                'Most likely reason: time for current game passed. Please login to trainer panel and check your games list',
            event: 'wronggcode',
        },
        missingcredentials: {
            title: 'Missing game credentials.',
            message:
                'Most likely reason: you did copy/paste URL from another device. Please login to your trainer panel and Activate/Continue the game.',
            event: 'wronggcode',
        },
        diceTitle: 'Toss a dice and input your result',
        diceOr: 'OR',
    },
};
