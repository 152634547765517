<script>
    import { createEventDispatcher } from 'svelte';
    export let lang = '';
    export let protection = {};
    const dispatch = createEventDispatcher();
    // export let bgClass = '';
    // export let bgImage = '';
    export let index;
    // export let name;
    let currBgImage = 'start';
    // export let isActive = false;
    function toggleActive() {
        protection.isActive = !protection.isActive;
        dispatch('state_changed', {
            name: protection.name,
            type: 'protections',
            isActive: protection.isActive,
            index,
        });
    }
    $: protection.isActive
        ? (currBgImage = `/img/boxes/${lang}/${protection.image}_hover.png`)
        : (currBgImage = `/img/boxes/${lang}/${protection.image}.png`);
</script>

{#if currBgImage}
    <div
        class={protection.class}
        style="background-image:url({currBgImage})"
        on:click={toggleActive}
    />
{/if}
