<script>
    export let lang = '';
    import { createEventDispatcher } from 'svelte';
    const dispatch = createEventDispatcher();
    export let currentData = {};
    export let isProtected = false;
    export let diceResult = 1;
    let currDesc = '';
    let currKarma = '';
    if (
        (currentData.effect === 'display' ||
            currentData.effect === 'display/toss') &&
        isProtected
    ) {
        currDesc = currentData.protectedDescription;
        currKarma = String(currentData.protectedKarma);
    } else if (currentData.effect === 'toss/toss' && !isProtected) {
        currKarma = String(currentData[`worseKarma${diceResult}`]);
        currDesc = currentData[`worseDescription${diceResult}`];
    } else {
        currKarma = String(currentData[`badKarma${diceResult}`]);
        currDesc = currentData[`badDescription${diceResult}`];
    }
</script>

<div class="popup">
    <div class="popup_content">
        <h1 class="popup_content_title">{currentData.name}</h1>
        <p class="popup_content_p">
            {currDesc}
        </p>
        <div
            class="popup_content_karma"
            style="background-image:url(/img/karma/{lang}/karma{currKarma}.png)"
        />

        <div
            class="popup-content-OK--active"
            on:click={() => dispatch('close_results')}
        />
    </div>
</div>
