import { writable } from 'svelte/store';

export const renderData = writable({
    tutorial: [
        {
            name: 'Vipers',
            class: 'main-places_vipers main-box',
            image: 'vipers',
            isActive: false,
        },
        {
            name: 'Shrubbery',
            class: 'main-places_shrubbery main-box',
            image: 'shrubbery',
            isActive: false,
        },
        { name: 'Blank', class: 'main-places_dead main-box' },
        { name: 'Blank', class: 'main-places_dead main-box' },
        { name: 'Blank', class: 'main-places_dead main-box' },
        { name: 'Blank', class: 'main-places_dead main-box' },
        { name: 'Blank', class: 'main-places_dead main-box' },
        { name: 'Blank', class: 'main-places_dead main-box' },
        { name: 'Blank', class: 'main-places_dead main-box' },
        { name: 'Blank', class: 'main-places_dead main-box' },
        { name: 'Blank', class: 'main-places_dead main-box' },
        { name: 'Blank', class: 'main-places_dead main-box' },
        { name: 'Blank', class: 'main-places_dead main-box' },
        { name: 'Blank', class: 'main-places_dead main-box' },
        { name: 'Blank', class: 'main-places_dead main-box' },
    ],
    game: [
        {
            name: 'Village',
            class: 'main-places_village main-box',
            image: 'village',
            isActive: false,
        },
        {
            name: 'Swamps',
            class: 'main-places_swamps main-box',
            image: 'swamps',
            isActive: false,
        },
        {
            name: 'Mountains',
            class: 'main-places_mountains main-box',
            image: 'mountains',
            isActive: false,
        },
        {
            name: 'Footbridge',
            class: 'main_places_footbridge main-box',
            image: 'footbridge',
            isActive: false,
        },
        {
            name: 'Avalanche',
            class: 'main-places_avalanche main-box',
            image: 'avalanche',
            isActive: false,
        },
        {
            name: 'Generator',
            class: 'main-places_generator main-box',
            image: 'generator',
            isActive: false,
        },
        {
            name: 'Glade',
            class: 'main-places_glade main-box',
            image: 'glade',
            isActive: false,
        },
        {
            name: 'Ruins',
            class: 'main-places_ruins main-box',
            image: 'ruins',
            isActive: false,
        },
        {
            name: 'Forest',
            class: 'main-places_forest main-box',
            image: 'forest',
            isActive: false,
        },
        {
            name: 'Crossing',
            class: 'main-places_crossing main-box',
            image: 'crossing',
            isActive: false,
        },
        {
            name: 'Dragon',
            class: 'main-places_dragon main-box',
            image: 'dragon',
            isActive: false,
        },
        {
            name: 'Appiary',
            class: 'main-places_appiary main-box',
            image: 'appiary',
            isActive: false,
        },
        {
            name: 'Slippery',
            class: 'main-places_slippery main-box',
            image: 'slippery',
            isActive: false,
        },
        {
            name: 'Wilderness',
            class: 'main-places_wilderness main-box',
            image: 'wilderness',
            isActive: false,
        },
        {
            name: 'Volcano',
            class: 'main-places_volcano main-box',
            image: 'volcano',
            isActive: false,
        },
    ],
});

export const protectionsInit = writable([
    {
        name: 'breathing-spell',
        class: 'main-protection_breathing-spell main-box',
        image: 'breathing-spell',
        isActive: false,
    },
    {
        name: 'careful',
        class: 'main-protection_careful main-box',
        image: 'careful',
        isActive: false,
    },
    {
        name: 'helmet',
        class: 'main-protection_helmet main-box',
        image: 'helmet',
        isActive: false,
    },
    {
        name: 'breastplate',
        class: 'main-protection_breastplate main-box',
        image: 'breastplate',
        isActive: false,
    },
    {
        name: 'antiflame-spell',
        class: 'main-protection_antiflame-spell main-box',
        image: 'antiflame-spell',
        isActive: false,
    },
]);

export const preloadImages = [
    '/img/buttons/OK.png',
    '/img/buttons/OK_hover.png',
    '/img/buttons/confirmation.png',
    '/img/buttons/confirmation_hover.png',
    '/img/buttons/$$$language$$$/random_dead.png',
    '/img/buttons/$$$language$$$/random_hover.png',
    '/img/buttons/$$$language$$$/random.png',
    '/img/popup_background.png',
];
