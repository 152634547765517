import axios from 'axios';

export const getGameData = async (mode, credentials) => {
    const url = `/trener/checkgame`;
    const data = {
        mode,
        key: credentials.key,
        code: credentials.gamecode,
    };

    try {
        // console.log(data);
        const res = await axios({
            method: 'POST',
            url,
            data,
        });
        if (res.status === 200) {
            //console.log(' incoming data', res.data);
            //console.log(res.data.data);
            return 'valid';
        } else if (res.status === 201) {
            return 'wronggame';
        } else if (res.status === 202) {
            return 'wrongcode';
        } else if (res.status === 203) {
            return 'expired';
        } else {
            console.log('unknown status');
        }
    } catch (err) {
        console.log(err);
    }
};
