<script>
    import { createEventDispatcher } from 'svelte';
    const dispatch = createEventDispatcher();
    export let data = {};
</script>

<div class="popup">
    <div class="popup_content">
        <h1 class="popup_content_title">{data.title}</h1>
        <p class="popup_content_p">
            {data.message}
        </p>
        <!-- <div
            class="popup_content_karma"
            style="background-image:url(../img/karma/karma{currKarma}.png)"
        /> -->

        <div
            class="popup-content-OK--active"
            on:click={() => dispatch('popupConfirm')}
        />
    </div>
</div>
