<script>
    import { messages } from './gameData';

    export let diceResult = 0;
    export let lang = '';
    let tempResult = 0;
    function sendResult() {
        if (!tempResult) return;
        diceResult = tempResult;
    }
    function generateRandomResult() {
        if (isRandomDone) return;
        tempResult = 1 + Math.floor(Math.random() * 6);
        isRandomDone = true;
    }
    const results = [1, 2, 3, 4, 5, 6];
    let isRandomDone = false;
</script>

<div class="popup">
    <div class="popup_content">
        <h1 class="popup_content_title">{messages[lang].diceTitle}</h1>
        <div class="popup_content_dice">
            {#each results as result}
                <label
                    class={tempResult === result
                        ? `popup_content_dice--dice${result}--selected`
                        : `popup_content_dice--dice${result}`}
                >
                    <input
                        class="dice_radio"
                        type="radio"
                        name="dice"
                        value={result}
                        bind:group={tempResult}
                        disabled={isRandomDone}
                    /></label
                >
            {/each}
        </div>
        <h1 class="popup_content_title">{messages[lang].diceOr}</h1>
        <div
        class={isRandomDone
            ? 'popup_content_random--dead'
            : `popup_content_random--active popup_content_random_${lang}`}
            style="background-image:url({isRandomDone
                ? `/img/buttons/${lang}/random_dead.png`
                : `/img/buttons/${lang}/random.png`})"
            on:click={generateRandomResult}
        />
        <hr />
        <div
            class={tempResult
                ? 'popup-content-OK--active'
                : 'popup-content-OK--dead'}
            on:click={sendResult}
        />
    </div>
</div>
